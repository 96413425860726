<template>
  <div class="m">
    <div class="first">
      <div class="first_left">
        <div class="tnamec">住宅维修资金云管理平台自助服务</div>
        <div class="tnamee">Residential self-service fund management platform</div>
      </div>
      <div class="first_right">
        <div class="fr1">{{ Samplingtime }}</div>
        <div class="fr2"></div>
      </div>
    </div>
    <div class="second">
      <div class="left_">
        <div class="weather">
          <div class="weather1">
            <img style="width: 4.75rem;height: 4.75rem;margin-right: 1.25rem"
                 :src="'http://weather.cma.cn/static/img/w/icon/w'+weather.dayCode+'.png'"/>
            <div class="ssd"> {{ weather.high }}<span style="font-size: 1.375rem;font-weight: normal">℃</span></div>
          </div>
          <div class="weather2">
            <div class="w21">
              {{ weather.low }}~{{ weather.high }}<span style="font-size: 1.125rem;font-weight: normal">℃</span>
              {{ weather.dayText === weather.nightText ? weather.dayText : weather.dayText + '转' + weather.nightText }}
            </div>
            <!--              <div class="w22"></div>-->
          </div>
        </div>
        <div class="introduce">
          <div class="introduce_content">
            <div class="ic1">东乡住建局工作职责</div>
            <div class="ic2"></div>
            <div class="ic3">
              {{ introduce }}
            </div>
            <div class="ic4" @click="goIntroduce()">
              了解更多
            </div>
          </div>
        </div>
      </div>
      <div class="right_">
        <div class="right_up">
          <div class="right_up_left">
          </div>
          <div class="right_up_right">
            <div class="right_up_right_up" @click="goQuery">
              <div class="up_">
                <div class="wz1">
                  综合查询
                </div>
                <div class="wz2">
                  zonghechaxun
                </div>
              </div>
              <div class="down_">
                <div class="p1"></div>
                <div class="p2">
                  <img src="../assets/image/3.png">
                </div>
              </div>
            </div>
            <div class="right_up_right_down" @click="goHousing">
              <div class="up_">
                <div class="wz1">
                  房屋信息
                </div>
                <div class="wz2">
                  fangwuxinxi
                </div>
              </div>
              <div class="down_">
                <div class="p1"></div>
                <div class="p2">
                  <img src="../assets/image/4.png">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right_down">
          <div class="right_down_left" @click="goPlar()">
            <div class="up_">
              <div class="wz1">
                政策法规
              </div>
              <div class="wz2">
                zhengcexuanchuan
              </div>
            </div>
            <div class="down_">
              <div class="p1"></div>
              <div class="p2">
                <img src="../assets/image/5.png">
              </div>
            </div>
          </div>
          <div class="right_down_right" @click="goHousePayment">
            <div class="up_">
              <div class="wz1">
                房屋缴费
              </div>
              <div class="wz2">
                fangwujiaofei
              </div>
            </div>
            <div class="down_">
              <div class="p1"></div>
              <div class="p2">
                <img src="../assets/image/6.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      introduce: '根据中共临夏州委办公室、临夏州人民政府办公室《关于批转东乡族自治县机构改革方案的通知》（州委办发〔2019〕9号）和中共东乡县委办公室、东乡县人民政府办公室《关于贯彻落实<东乡族自治县机构改革方案>的实施意见》（县委办发〔2019〕13号），制定本规定。\n' +
        '东乡族自治县住房和城乡建设局（简称县住建局）是县政府工作部门，为正科级。',
      weather: {
        date: '',
        dayCode: 0,
        dayText: '',
        dayWindDirection: '',
        dayWindScale: '',
        high: 0,
        low: 0,
        nightCode: 0,
        nightText: '',
        nightWindDirection: '',
        nightWindScale: ''
      },
      Samplingtime: ''
    }
  },
  created() {
    this.$axios.post('https://weather.cma.cn/api/weather/view?stationid=').then(res => {
      this.weather = res.data.data.daily[0]
    })
  },
  mounted() {
    this.getCurrentTime()
    // eslint-disable-next-line no-use-before-define
    clearInterval(myTimeDisplay)
    const myTimeDisplay = setInterval(() => {
      this.getCurrentTime()
    }, 1000)
  },
  methods: {
    goPlar() {
      this.$router.push({path: '/plar'})
    },
    goQuery() {
      var idCard=this.$utils.getIdcard();
      if (idCard==""){
        this.$alert("请将身份证放识别区","消息提示");
        return false;
      }
      this.$router.push({path: '/query'})
    },
    goIntroduce() {
      this.$router.push({path: '/introduce'})
    },
    goHousing() {
      var idCard=this.$utils.getIdcard();
      if (idCard==""){
        this.$alert("请将身份证放识别区","消息提示");
        return false;
      }
      this.$router.push({path: '/housing'})
    },
    goHousePayment() {
      var idCard=this.$utils.getIdcard();
      if (idCard==""){
        this.$alert("请将身份证放识别区","消息提示");
        return false;
      }

      this.$router.push({path: '/housePayment'})
    },
    getCurrentTime() {
      var _this = this
      const yy = new Date().getFullYear()
      const mm = (new Date().getMonth() + 1) < 10 ? ('0' + (new Date().getMonth() + 1)) : (new Date().getMonth() + 1)
      const dd = new Date().getDate() < 10 ? ('0' + new Date().getDate()) : new Date().getDate()
      const hh = new Date().getHours() < 10 ? ('0' + new Date().getHours()) : new Date().getHours()
      const mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
      _this.gettime = yy + '年' + mm + '月' + dd + '日 ' + hh + ':' + mf + ':' + ss
      this.Samplingtime = _this.gettime
    }
  }
}
</script>

<style scoped lang="stylus">
.m {
  background #BCE1DF
  height: 100%;
  width: 100%;
  padding: 0.625rem 0.625rem;

  .first {
    width: 100%;
    height: 10%;
   /* background-image: linear-gradient(to top, #019B84 , #00B097);*/
    background #006666 url("../assets/image/title_bg.png") no-repeat center
    background-size 100% 100%
    display flex;
    flex-direction row

    .first_left {
      width 50%;
      height 100%

      .tnamec {
        margin-top 0.75rem
        margin-left 1.25rem
        font-size: 1.625rem;
        color: #FFFFFF;
        letter-spacing: 0;
        text-shadow: 0 0.25rem 0.625rem rgba(0, 20, 119, 0.20);
      }

      .tnamee {
        margin-left 1.25rem
        font-size: 0.875rem;
        color: #FFFFFF;
        letter-spacing: 0.9px;
        text-shadow: 0 0.25rem 0.625rem rgba(0, 20, 119, 0.20);
      }
    }

    .first_right {
      width 100%
      display flex
      flex-direction column

      .fr1 {
        height 70%
        display flex
        flex-direction row
        align-items center
        justify-content right
        padding-right 1.25rem
        padding-top 0.375rem
        font-weight: 400;
        font-size: 1.25rem;
        color: #FFFFFF;
        letter-spacing: 0;
      }

      .fr2 {
        height 30%
        border-right 0.125rem solid #3F52AF;
      }
    }
  }

  .second {
    background #006666
    width: 100%;
    height: 90%;
    border-bottom: 0.125rem solid #3F52AF;
    border-left: 0.125rem solid #3F52AF;
    border-right: 0.125rem solid #3F52AF;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    padding: 0.625rem 1.875rem;
    display: flex;
    flex-direction: row;

    .left_ {
      height 100%

      .weather {
        width: 20.875rem;
        height: 25%;
        padding-top: 1.375rem;
        background url("../assets/image/tianqi.png") no-repeat center
        background-size 100% 100%
        border-radius: 0.75rem;

        .weather1 {
          //padding-left:40px;
          display: flex;
          flex-direction: row;
          justify-content center

          .ssd {
            font-size: 3.5rem;
            color: #D9EAFF;
            height: 4.75rem;
            line-height: 4.75rem;
            font-weight: bold;
          }
        }

        .weather2 {
          display: flex;
          flex-direction: row;
          justify-content center
          font-weight: 400;
          font-size: 150%;
          color: #FFFFFF;

          .w21 {
            width: 100%;
            opacity: 0.6;
            text-align center
            letter-spacing: 0.4 .75rem;
          }

          .w22 {
            opacity: 0.6;
            letter-spacing: 0.4 .75rem;
            width: 60%;
            text-align: right;
            margin-right 1.25rem
          }
        }

      }

      .introduce {
        width: 20.875rem;
        height: calc(75% - 0.625rem);
        margin-top: 0.625rem;
        background url("../assets/image/l_bottom.png") no-repeat center
        background-size 100% 100%
        border-radius: 0.75rem;

        .introduce_content {
          width: 100%;
          height 100%
          padding: 1.875rem 2.875rem 0 2.875rem;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow-y auto
          position relative

          .ic1 {
            width: 12.5rem;
            height: 3.45rem;
            font-size: 2rem;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: center;
            text-shadow: 0 0.375rem 0.94rem #09449D;
          }

          .ic2 {
            flex-shrink: 0;
            margin-bottom: 0.3rem;
            width: 4.75rem;
            height: 4.75rem;
            background-image url("../assets/image/1.png");
            background-size 100% 100%
            //box-shadow: inset 0 3px 3px 0 rgba(0,76,196,0.35), inset 0 -3px 0.375rem 0 rgba(73,145,255,0.55);
            border-radius: 50% 50%;
            margin-top 2rem
          }

          .ic3 {
            height: 18.5rem;
            opacity: 0.8;
            font-size: 1.2rem;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: justify;
            line-height: 2.3rem;
            text-overflow: ellipsis;
            overflow-y auto
            text-indent 2.4em
            margin-bottom 5rem
          }

          .ic4 {
            margin-top 50.375rem;
            margin-bottom 0.625rem
            text-align center;
            align-items center
            line-height 3.125rem
            width: 80%;
            height: 3.125rem;
            color #ffff
           /* background-image: linear-gradient(180deg, #BDEDFF 0%, #5C9AFB 100%);*/
            background url("../assets/image/l_button.png") no-repeat center
            background-size 100% 100%
            border: 0.0625rem solid #9FD4FE;
            box-shadow: 0 0.25rem 0.75rem 0 #4E8DFF;
            border-radius: 0.375rem;
            position absolute
            bottom 0.625rem
            cursor pointer
          }
        }

      }
    }

    .right_ {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;

      .right_up {
        height: 60%;
        display: flex;
        flex-direction: row;

        .right_up_left {
          margin 0 0 0 0.625rem;
          border-radius 0.75rem
          width: 60%;
          //background-image: linear-gradient(135deg, #297CF3 0%, #02E8E5 100%);
          background-image url("../assets/image/7.png")
          background-size 100% 100%
        }

        .right_up_right {
          width: 40%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end

          .right_up_right_up {
            display flex
            flex-direction column
            margin 0 0 0 0.625rem;
            height 48%;
            border-radius 0.75rem
            background-image: linear-gradient(135deg, #FEA211 0%, #FEE363 100%);
            cursor pointer

            .up_ {
              height 50%

              .wz1 {
                font-weight: 600;
                font-size: 200%;
                color: #FFFFFF;
                letter-spacing: 0.0625rem;
                padding-top 10%;
                padding-left 10%
              }

              .wz2 {
                padding-left 10%
                opacity 0.6
                font-weight: 500;
                font-size: 0.875rem;
                color: #F7FBFF;
                letter-spacing: 0.138rem;
              }
            }

            .down_ {
              width 100%
              height 50%
              display flex
              flex-direction row

              .p1 {
                height 100%
                width 50%
              }

              .p2 {
                height 100%
                width 50%
                background: #fece4d;
                border-bottom-right-radius 5%
                border-top-right-radius 70%
                border-bottom-left-radius 60%
                border-top-left-radius 60%
                display flex
                flex-direction row
                align-items center
                justify-content center

                img {
                  width 6rem
                  height 6rem
                }
              }
            }
          }

          .right_up_right_down {
            margin 0.625rem 0 0 0.625rem;
            height 49%
            background-image: linear-gradient(135deg, #297CF3 0%, #02E8E5 100%);
            border-radius 0.75rem
            cursor pointer;

            .up_ {
              height 40%

              .wz1 {
                font-weight: 600;
                font-size: 1.875rem;
                color: #FFFFFF;
                letter-spacing: 0.0625rem;
                padding-top 10%;
                padding-left 10%
              }

              .wz2 {
                padding-left 10%
                opacity 0.6
                font-weight: 500;
                font-size: 0.875rem;
                color: #F7FBFF;
                letter-spacing: 0.263rem;
              }
            }

            .down_ {
              width 100%
              height 60%
              display flex
              flex-direction row

              .p1 {
                height 100%
                width 50%
              }

              .p2 {
                height 100%
                width 50%
                background: #fece4d;
                border-bottom-right-radius 0.625rem
                border-top-right-radius 0
                border-bottom-left-radius 0
                border-top-left-radius 98%
                opacity 0.8
                background: linear-gradient(90deg, #0BCFE8 0%, #3DA3EB 100%);
                display flex
                flex-direction row
                align-items center
                justify-content center

                img {
                  width 6rem
                  height 6rem
                }
              }
            }
          }
        }
      }

      .right_down {
        margin 0.625rem 0 0 0.625rem;
        height 40%;
        width 100%
        display flex;
        flex-direction row;

        .right_down_left {
          width 40%;
          height 100%
          background-image: linear-gradient(135deg, #FB5396 0%, #FA9C44 100%);
          border-radius 1rem;
          margin-right 0.625rem;
          cursor pointer

          .up_ {
            height 40%

            .wz1 {
              font-weight: 600;
              font-size: 1.875rem;
              color: #FFFFFF;
              letter-spacing: 0.5rem;
              padding-top 6%;
              padding-left 10%
            }

            .wz2 {
              padding-left 10%
              opacity 0.6
              font-weight: 500;
              font-size: 0.875rem;
              color: #F7FBFF;
              letter-spacing: 0.0756rem;
            }
          }

          .down_ {
            width 100%
            height 60%
            display flex
            flex-direction row

            .p1 {
              height 100%
              width 30%
            }

            .p2 {
              height 100%
              width 70%
              background: #f2705350;
              border-bottom-right-radius 0.625rem
              border-top-right-radius 0
              border-bottom-left-radius 0
              border-top-left-radius 200%
              display flex
              flex-direction row
              align-items center
              justify-content center

              img {
                width 6rem
                height 6rem
                margin-left 30%
              }
            }
          }
        }

        .right_down_right {
          width 60%;
          height 100%
          margin-right 0.625rem
          background-image: linear-gradient(135deg, #25B764 0%, #75DFAB 100%);
          border-radius 16px;
          cursor pointer
          .up_ {
            height 30%

            .wz1 {
              font-weight: 600;
              font-size: 1.875rem;
              color: #FFFFFF;
              letter-spacing: 0.5rem;
              padding-top 4%;
              padding-left 10%
            }

            .wz2 {
              padding-left 10%
              opacity 0.6
              font-weight: 500;
              font-size: 0.875rem;
              color: #F7FBFF;
              letter-spacing: 0.0238rem;
            }
          }

          .down_ {
            width 100%
            height 70%
            display flex
            flex-direction row

            .p1 {
              height 100%
              width 30%
            }

            .p2 {
              height 100%
              width 70%
              background: #66d69b60;
              opacity 0.8
              border-bottom-right-radius 0.625rem
              border-top-right-radius 0
              border-bottom-left-radius 0
              border-top-left-radius 200%
              display flex
              flex-direction row
              align-items center
              justify-content center

              img {
                width 6rem
                height 6rem
                margin-left 54%
              }
            }
          }
        }
      }
    }
  }
}

</style>
